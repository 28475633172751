@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=fallback');

// AOS styles
$aos-distance: 10px;
@import 'node_modules/aos/src/sass/aos';

html {
  scroll-behavior: smooth;
}

:focus {
  outline: 0;
}

@font-face {
  font-family: 'HKGrotesk';
  src: url("./assets/fonts/hk-grotesk/HKGrotesk-Black.otf") format("opentype");
}

@font-face {
  font-family: 'HKGroteskREG';
  src: url("./assets/fonts/hk-grotesk/HKGrotesk-Regular.otf") format("opentype");
}

@font-face {
  font-family: 'HKGroteskB';
  font-weight: bold;
  src: url("./assets/fonts/hk-grotesk/HKGrotesk-Bold.otf") format("opentype");
}

@font-face {
  font-family: 'SerifPro';
  font-weight: bold;
  src: url("./assets/fonts/serif-pro/source-serif-pro.regular.otf") format("opentype");
}